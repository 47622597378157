import React from 'react';
import { ItemStyles, SearchPageStyles } from '../../styles/main.css';

const { ItemImage, ItemContent, ItemPriceWrapper, ItemPrice, ItemDiscountWrapper, ItemDiscount, ItemTitle, ItemCategory } = ItemStyles;
const { SearchItemWrapper } = SearchPageStyles;

const Item = ({ image, price, discount, title, theme, category, bgColor }) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <>
            <SearchItemWrapper bgColor={bgColor}>
                <ItemImage src={image} alt="item" />
                <ItemContent>
                    <ItemPriceWrapper>
                        <ItemPrice colorTheme={theme}>{formatPrice(price)} ₽</ItemPrice>
                        {
                            discount > 0 &&
                            <ItemDiscountWrapper colorTheme={theme}>
                                <ItemDiscount colorTheme={theme}>-{discount}%</ItemDiscount>
                            </ItemDiscountWrapper>
                        }
                    </ItemPriceWrapper>
                    <ItemTitle colorTheme={theme}>{title}</ItemTitle>
                    <ItemCategory colorTheme={theme}>{category}</ItemCategory>
                </ItemContent>
            </SearchItemWrapper>
        </>
    );
}

export default Item;
