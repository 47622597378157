import React from 'react';
import {CartStyles} from '../../styles/main.css';

const { CartOrderRadioWrapper, CartOrderRadioCircle, CartOrderRadio, CartOrderRadioTitle } = CartStyles;

const Radio = ({ title, active, handleActive, theme }) => {
    return (
        <>
            <CartOrderRadioWrapper onClick={handleActive}>
                <CartOrderRadioCircle colorTheme={theme}>
                    <CartOrderRadio colorTheme={theme} isActive={active}></CartOrderRadio>
                </CartOrderRadioCircle>
                <CartOrderRadioTitle colorTheme={theme}>{title}</CartOrderRadioTitle>
            </CartOrderRadioWrapper>

        </>
    );
}

export default Radio;
