import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Container, PromoPageStyles} from "../styles/main.css";
import {get} from "../lib/api";
import Item from "../components/Item";
import filtersIcon from "../assets/common/filters.svg";
import filters from "./Filters";

const { PromoHeaderWrapper, PromoHeaderTitle, PromoHeaderFilters, PromoHeaderFilterIcon, PromoItemsWrapper } = PromoPageStyles;

const Promo = () => {
    const { theme } = useTheme();
    const { promoId } = useParams();
    const { state } = useLocation();
    const [ items, setItems ] = useState();
    const [ title, setTitle ] = useState();
    const [platform, setPlatform] = useState(state?.platform);
    const [language, setLanguage] = useState(state?.language);
    const [category, setCategory] = useState(state?.category);
    const [price, setPrice] = useState(state?.price);

    const user = useSelector(state => state.userInfo);

    useEffect(() => {
        get('/items/getSubPage', {token: user.token, subpage_id: promoId}).then((res) => {
            setTitle(res.sub_page.name);
            let filterItems = res.sub_page.items;
            if (platform) {
                filterItems = filterItems.filter((item) => item.platforms.includes(platform));
            }
            if (language) {
                filterItems = filterItems.filter((item) => item.language === language);
            }
            if (category) {
                filterItems = filterItems.filter((item) => item.category === category);
            }
            if (price) {
                filterItems = filterItems.filter((item) => item.price <= price);
            }
            setItems(filterItems);
        }).catch((err) => {
            console.log(err);
        });
    }, [promoId]);

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.hide();

    const goToFilters = () => {
        navigate('/filters', {state: {prevPage: `/promo/${promoId}`, searchValue: null, platform: platform, language: language, category: category, price: price}});
    }

    return (
        <>
            <Container>
                <PromoHeaderWrapper colorTheme={theme}>
                    <PromoHeaderTitle colorTheme={theme}>{title}</PromoHeaderTitle>
                    <PromoHeaderFilters colorTheme={theme} onClick={goToFilters}>
                        <PromoHeaderFilterIcon src={filtersIcon} alt="filters"/>
                    </PromoHeaderFilters>
                </PromoHeaderWrapper>
                <PromoItemsWrapper>
                    {items && items.map((item, index) => {
                        return (
                            <div onClick={() => navigate(`/item/${item.id}`)} key={index}>
                                <Item image={item.image} price={item.price} discount={item.discount} title={item.title} category={item.category} theme={theme} bgColor={theme.cardColor} />
                            </div>
                        )
                    })}
                </PromoItemsWrapper>
            </Container>
        </>
    );
}

export default Promo;
