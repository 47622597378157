import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useNavigate} from "react-router-dom";
import {Container, PersonalPageStyles} from "../styles/main.css";
import PersonalItem from "../components/Personal/PersonalItem";
import Star from "../components/Icons/Star";
import List from "../components/Icons/List";
import Air from "../components/Icons/Air";
import UsersGroup from "../components/Icons/UsersGroup";
import {get} from '../lib/api';
import {useSelector} from 'react-redux';

const { PersonalTitle, PersonalActionsWrapper, PersonalAction, PersonalActionContent, PersonalActionIcon, PersonalActionText, PersonalActionArrow } = PersonalPageStyles;

const Personal = () => {
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);

    const [communityUrl, setCommunityUrl] = useState('');
    const [supportUrl, setSupportUrl] = useState('');

    useEffect(() => {
        get('/users/getOption', {token: user.token, key: 'public_url'}).then((res) => {
            setCommunityUrl(res.option);
        });
        get('/users/getOption', {token: user.token, key: 'support_url'}).then((res) => {
            setSupportUrl(res.option);
        })
    }, []);

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.hide();

    const goToFavourite = () => {
        navigate('/favourite');
    }

    const goToHistory = () => {
        navigate('/history');
    }

    const goToHelp = () => {
        tg.openTelegramLink(supportUrl);
    }

    const goToCommunity = () => {
        tg.openTelegramLink(communityUrl);
    }

    return (
        <>
            <Container>
                <PersonalTitle colorTheme={theme}>Личный кабинет</PersonalTitle>
                <PersonalActionsWrapper colorTheme={theme}>
                    <PersonalItem colorTheme={theme} icon={Star} text="Избранное" onClick={goToFavourite}/>
                    <PersonalItem colorTheme={theme} icon={List} text="История покупок" onClick={goToHistory}/>
                    <PersonalItem colorTheme={theme} icon={Air} text="Нужна помощь" onClick={goToHelp}/>
                    <PersonalItem colorTheme={theme} icon={UsersGroup} text="Наше сообщество" onClick={goToCommunity}/>
                </PersonalActionsWrapper>
            </Container>
        </>
    );
}

export default Personal;
