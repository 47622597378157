export const lightTheme = {
    backgroundColor: '#E7E7E7',
    titleColor: '#000',
    subTitleColor: '#747474',
    cardColor: '#FFFFFF',
    secondaryCardColor: '#EEE',
    evenBackgroundColor: '#FFFFFF',
    oddBackgroundColor: '#E7E7E7',
    cartItemBackgroundColor: '#FFFFFF',
    tabBorderColor: '#BCBCBC',
    radioDisableColor: '#BCBCBC',
    inputColor: '#E7E7E7',
    inputTextColor: '#717171',
    mainButtonColor: '#000000',
    mainButtonTextColor: '#FFFFFF',
};

export const darkTheme = {
    backgroundColor: '#2B2B2B',
    titleColor: '#FFFFFF',
    subTitleColor: '#BEBEBE',
    cardColor: '#000',
    secondaryCardColor: '#000',
    evenBackgroundColor: '#484848',
    oddBackgroundColor: '#2B2B2B',
    cartItemBackgroundColor: '#000',
    tabBorderColor: '#D9D9D9',
    radioDisableColor: '#404040',
    inputColor: '#E7E7E7',
    inputTextColor: '#717171',
    mainButtonColor: '#FFFFFF',
    mainButtonTextColor: '#000000',
};
