import React, {useEffect, useState} from 'react';
import { Container } from '../styles/main.css'
import Search from '../components/Search/Search';
import BestItems from '../components/BestItems/BestItems';
import { useTheme } from '../context/ThemeContext';
import Category from "../components/Category/Category";
import config from "../config";
import {useSelector} from "react-redux";
import {get} from "../lib/api";
import {useNavigate} from "react-router-dom";
import loaderImage from "../assets/common/loader.svg";

const Main = () => {
    const tg = window.Telegram.WebApp;
    const { theme } = useTheme();
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(true);
    const [ sellItems, setSellItems ] = useState();
    const [ pages, setPages ] = useState();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;
    const totalCart = cart.items.length

    const mainButton = tg.MainButton;
    mainButton.text = "Перейти в корзину"
    mainButton.enable();

    const goToCart = () => {
        navigate('/cart');
    }

    const goToPersonal = () => {
        navigate('/personal');
    }

    const goToSearch = () => {
        navigate('/search');
    }

    useEffect(() => {
        if (totalCart > 0) {
            mainButton.onClick(goToCart);
            mainButton.color = theme.mainButtonColor;
            mainButton.textColor = theme.mainButtonTextColor;
            mainButton.show();
            return () => {
                mainButton.offClick(goToCart);
            }
        } else {
            mainButton.hide();
        }
    }, [cart]);

    useEffect(() => {
        get('/items/getSellItems', {token: user.token}).then((res) => {
            setSellItems(res.items);
        }).catch((err) => {
            console.log(err);
        })
        get('/items/getPages', {token: user.token}).then((res) => {
            setPages(res.pages);
        }).catch((err) => {
            console.log(err);
        })
        setLoading(false);
    }, []);

    if (loading) {
        return <div
            style={{textAlign: 'center', padding: '20px', height: '100vh', display: 'flex', justifyContent: 'center'}}>
            <img src={loaderImage} alt='Loading' style={{width: 64}}/>
        </div>
    }

    return (
        <main className="main_page">
            <Container>
                <Search colorTheme={theme} cartCount={totalCart} goToCart={goToCart} goToSearch={goToSearch} goToPersonal={goToPersonal} />
            </Container>
            <BestItems />
            <Category
                icon={`${config.STATIC_BASE_URL}/leaders.png`}
                title="Лидеры продаж"
                type={'slider'}
                items={sellItems}
                theme={theme}
                index={0}
            />
            {
                pages && pages.map((page, index) => (
                    <Category
                        key={index}
                        icon={page.icon}
                        title={page.name}
                        type={page.type}
                        items={page.items}
                        promos={page.sub_pages}
                        theme={theme}
                        index={index + 1}
                    />
                ))
            }
        </main>
    );
}

export default Main;
