import React from 'react';
import {CategoryStyles, Container} from '../../styles/main.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Item from "../Item";
import {useNavigate} from "react-router-dom";
import Promo from "../Promo";


const { CategoryBackground, CategoryWrapper, CategoryTitleWrapper, CategoryIcon, CategoryTitle, ItemGridWrapper, ItemGridSelf } = CategoryStyles;

const Category = ({ index, icon, title, type, items, theme, promos }) => {
    const navigate = useNavigate();
    const isEven = index % 2 === 0;
    return (
        <>
            <CategoryBackground backgroundColor={isEven ? theme.evenBackgroundColor : theme.oddBackgroundColor}>
                <Container>
                    <CategoryWrapper>
                        <CategoryTitleWrapper>
                            {
                                icon && <CategoryIcon src={icon} alt="icon" />
                            }
                            <CategoryTitle colorTheme={theme}>{title}</CategoryTitle>
                        </CategoryTitleWrapper>
                    </CategoryWrapper>
                </Container>
                {
                    type === 'slider' &&
                    <Swiper
                        slidesPerView={2.1}
                        spaceBetween={20}
                        grabCursor={true}
                        className={'defaultSwiper'}
                        loop
                    >
                        {items && items.map((item, index) => {
                            return (
                                <SwiperSlide key={index} onClick={() => navigate(`/item/${item.id}`)}>
                                    <Item image={item.image} price={item.price} discount={item.discount} title={item.title} category={item.category} theme={theme} bgColor={isEven ? theme.secondaryCardColor : theme.cardColor} />
                                </SwiperSlide>
                            )
                        })}
                        {promos && promos.map((promo, index) => {
                            return (
                                <SwiperSlide key={index} onClick={() => navigate(`/promo/${promo.id}`)}>
                                    <Promo image={promo.image} />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                }
                {
                    type === 'grid' &&
                    <Container>
                        <ItemGridWrapper>
                            {promos && promos.map((promo, index) => {
                                return (
                                    <div onClick={() => navigate(`/promo/${promo.id}`)} key={index}>
                                        <Promo image={promo.image} />
                                    </div>
                                )
                            })}
                            {items && items.map((item, index) => {
                                return (
                                    <div onClick={() => navigate(`/item/${item.id}`)} key={index}>
                                        <Item image={item.image} price={item.price} discount={item.discount} title={item.title} category={item.category} theme={theme} bgColor={isEven ? theme.secondaryCardColor : theme.cardColor} />
                                    </div>
                                )
                            })}
                        </ItemGridWrapper>
                    </Container>

                }

            </CategoryBackground>
        </>
    );
}

export default Category;
