import React from 'react';

const SuccessPayment = () => {
    const tg = window.Telegram.WebApp;
    if (tg) {
        tg.ready();
        tg.disableClosingConfirmation();
        tg.close();
    }
    return (
        <>
            <h1>Оплата прошла успешно</h1>
        </>
    );
}

export default SuccessPayment;
