const Arrow = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_2034_152)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.06792 0L5.3999 1.68691L15.3131 12.0158L14.2559 13.1174L14.2619 13.1109L5.45392 22.2879L7.0967 24C9.5315 21.4644 16.3283 14.3834 18.5999 12.0158C16.9127 10.2566 18.5579 11.9712 7.06792 0Z"
                      fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_2034_152">
                    <rect width="24" height="24" fill={color}/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Arrow;