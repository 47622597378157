const Heart = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M2.66675 12.1828C2.66675 18.6667 8.02599 22.1219 11.9491 25.2145C13.3334 26.3059 14.6667 27.3333 16.0001 27.3333C17.3334 27.3333 18.6667 26.3059 20.0511 25.2145C23.9742 22.1219 29.3334 18.6667 29.3334 12.1828C29.3334 5.69888 21.9998 1.10062 16.0001 7.33418C10.0003 1.10062 2.66675 5.69888 2.66675 12.1828Z"
                fill={color}/>
        </svg>
    );
}

export default Heart;