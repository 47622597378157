const UsersGroup = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path
                d="M24.5418 11.875C24.5418 14.9356 22.0607 17.4167 19.0002 17.4167C15.9396 17.4167 13.4585 14.9356 13.4585 11.875C13.4585 8.81442 15.9396 6.33334 19.0002 6.33334C22.0607 6.33334 24.5418 8.81442 24.5418 11.875Z"
                fill={color}/>
            <path opacity="0.4"
                  d="M30.8752 11.875C30.8752 14.0611 29.1029 15.8333 26.9168 15.8333C24.7307 15.8333 22.9585 14.0611 22.9585 11.875C22.9585 9.68888 24.7307 7.91667 26.9168 7.91667C29.1029 7.91667 30.8752 9.68888 30.8752 11.875Z"
                  fill={color}/>
            <path opacity="0.4"
                  d="M7.125 11.875C7.125 14.0611 8.89721 15.8333 11.0833 15.8333C13.2695 15.8333 15.0417 14.0611 15.0417 11.875C15.0417 9.68888 13.2695 7.91667 11.0833 7.91667C8.89721 7.91667 7.125 9.68888 7.125 11.875Z"
                  fill={color}/>
            <path
                d="M28.5 26.125C28.5 29.1856 24.2467 31.6667 19 31.6667C13.7533 31.6667 9.5 29.1856 9.5 26.125C9.5 23.0644 13.7533 20.5833 19 20.5833C24.2467 20.5833 28.5 23.0644 28.5 26.125Z"
                fill={color}/>
            <path opacity="0.4"
                  d="M34.8334 26.125C34.8334 28.3111 31.9978 30.0833 28.5001 30.0833C25.0023 30.0833 22.1667 28.3111 22.1667 26.125C22.1667 23.9389 25.0023 22.1667 28.5001 22.1667C31.9978 22.1667 34.8334 23.9389 34.8334 26.125Z"
                  fill={color}/>
            <path opacity="0.4"
                  d="M3.16675 26.125C3.16675 28.3111 6.00228 30.0833 9.50008 30.0833C12.9979 30.0833 15.8334 28.3111 15.8334 26.125C15.8334 23.9389 12.9979 22.1667 9.50008 22.1667C6.00228 22.1667 3.16675 23.9389 3.16675 26.125Z"
                  fill={color}/>
        </svg>);
}

export default UsersGroup;