import React, { useEffect, useRef, useState } from 'react';
import { Container, ItemInfoStyles } from '../../styles/main.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Heart from '../Icons/Heart';
import { get } from '../../lib/api';
import ItemEdition from './ItemEdition';
import AccordionItem from './AccordionItem';

const {
    ItemInfoPreviewWrapper,
    ItemInfoPreview,
    ItemInfoWrapper,
    ItemInfoMainWrapper,
    ItemInfoMainContent,
    ItemInfoMainPriceWrapper,
    ItemInfoMainPrice,
    ItemInfoMainDiscountWrapper,
    ItemInfoMainDiscount,
    ItemInfoMainTitleWrapper,
    ItemInfoMainTitle,
    ItemInfoDescriptionWrapper,
    ItemInfoDescriptionContent,
    ItemInfoDescription,
    ItemInfoDetailWrapper,
    ItemInfoDetailContent,
    ItemInfoDetailPlatformWrapper,
    ItemInfoDetailPlatformTitle,
    ItemInfoDetailPlatformContentWrapper,
    ItemInfoDetailPlatformContent,
    ItemInfoDetailLanguageWrapper,
    ItemInfoDetailLanguageTitle,
    ItemInfoDetailLanguageContent,
    ItemInfoDetailReleaseDateWrapper,
    ItemInfoDetailReleaseDateTitle,
    ItemInfoDetailReleaseDateContent,
    ItemInfoMainWidgetsWrapper,
    ItemInfoMainFavouriteWrapper,
    ItemInfoEditionContent,
    ItemInfoEditionTitle,
    ItemInfoEditionItemsWrapper
} = ItemInfoStyles;

const ItemInfo = ({item, setItem, items, theme, user}) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    // const formatDescription = (description) => {
    //     return description.split('\n').map((item, key) => {
    //         return <p key={key}>{item}</p>
    //     });
    // }

    const formatDescription = (input) => {
        const paragraphs = input.split('\n').map((line, index) => `<span key=${index}>${line}<br/></span>`);
        return paragraphs.join('');
    }

    const handleFavourite = () => {
        get('/users/switchFavourite', {token: user.token, item_id: item.id}).then((res) => {
            if (res.success) {
                setItem({...item, favourite: res.favourite});
            }
        });
    }

    const getFavouriteColor = () => {
        return item.favourite ? '#F92E2E' : '#FFFFFF';
    }

    const changeEdition = (itemId) => {
        setItem(items.find(item => item.id === itemId));
    }

    const sliderRef = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDown(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDown(false);
    };

    const handleMouseUp = () => {
        setIsDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
        <>
            <ItemInfoPreviewWrapper>
                <ItemInfoPreview src={item.image} alt={'Item Preview'}/>
            </ItemInfoPreviewWrapper>
            <ItemInfoWrapper colorTheme={theme}>
                <ItemInfoMainWrapper colorTheme={theme}>
                    <ItemInfoMainContent colorTheme={theme}>
                        <Container>
                            <ItemInfoMainWidgetsWrapper>
                                <ItemInfoMainPriceWrapper colorTheme={theme}>
                                    <ItemInfoMainPrice
                                        colorTheme={theme}>{formatPrice(item.price)} ₽</ItemInfoMainPrice>
                                    {item.discount > 0 &&
                                        <ItemInfoMainDiscountWrapper colorTheme={theme}>
                                            <ItemInfoMainDiscount
                                                colorTheme={theme}>{`-${item.discount}%`}</ItemInfoMainDiscount>
                                        </ItemInfoMainDiscountWrapper>
                                    }
                                </ItemInfoMainPriceWrapper>
                                <ItemInfoMainFavouriteWrapper colorTheme={theme} onClick={handleFavourite}>
                                    <Heart color={getFavouriteColor()}/>
                                </ItemInfoMainFavouriteWrapper>
                            </ItemInfoMainWidgetsWrapper>
                        </Container>
                    </ItemInfoMainContent>
                    <ItemInfoMainTitleWrapper colorTheme={theme}>
                        <Container>
                            <ItemInfoMainTitle colorTheme={theme}>{item.title}</ItemInfoMainTitle>
                        </Container>
                    </ItemInfoMainTitleWrapper>
                </ItemInfoMainWrapper>
                {
                    items.length > 1 ?
                        <ItemInfoEditionContent colorTheme={theme}>
                            <Container>
                                <ItemInfoEditionTitle colorTheme={theme}>Издания</ItemInfoEditionTitle>
                                <ItemInfoEditionItemsWrapper ref={sliderRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
                                    {items.map((itemEdition, key) => {
                                        return <ItemEdition key={key} title={itemEdition.edition_name}
                                                            price={itemEdition.price} theme={theme}
                                                            onClick={() => changeEdition(itemEdition.id)}
                                                            isActive={itemEdition.id === item.id}/>
                                    })}
                                </ItemInfoEditionItemsWrapper>
                            </Container>
                        </ItemInfoEditionContent>
                        :
                        null
                }
                <ItemInfoDescriptionWrapper colorTheme={theme}>
                    <ItemInfoDescriptionContent colorTheme={theme}>
                        <Container>
                            <ItemInfoDescription
                                colorTheme={theme}>
                                <p dangerouslySetInnerHTML={{ __html: formatDescription(item.description) }} ></p>
                            </ItemInfoDescription>
                        </Container>
                    </ItemInfoDescriptionContent>
                </ItemInfoDescriptionWrapper>
                <ItemInfoDetailWrapper colorTheme={theme}>
                    <ItemInfoDetailContent colorTheme={theme}>
                        <ItemInfoDetailPlatformWrapper colorTheme={theme}>
                            <ItemInfoDetailPlatformTitle colorTheme={theme}>Платформа</ItemInfoDetailPlatformTitle>
                            <ItemInfoDetailPlatformContentWrapper colorTheme={theme}>
                                {item.platforms.map((platform, index) => (
                                    <ItemInfoDetailPlatformContent colorTheme={theme}
                                                                   key={index}>{platform}</ItemInfoDetailPlatformContent>
                                ))}
                            </ItemInfoDetailPlatformContentWrapper>
                        </ItemInfoDetailPlatformWrapper>
                        <ItemInfoDetailLanguageWrapper colorTheme={theme}>
                            <ItemInfoDetailLanguageTitle colorTheme={theme}>Язык</ItemInfoDetailLanguageTitle>
                            <ItemInfoDetailLanguageContent
                                colorTheme={theme}>{item.language}</ItemInfoDetailLanguageContent>
                        </ItemInfoDetailLanguageWrapper>
                        {/*<ItemInfoDetailReleaseDateWrapper colorTheme={theme}>*/}
                        {/*    <ItemInfoDetailReleaseDateTitle colorTheme={theme}>Дата*/}
                        {/*        релиза</ItemInfoDetailReleaseDateTitle>*/}
                        {/*    <ItemInfoDetailReleaseDateContent*/}
                        {/*        colorTheme={theme}>{item.release_date}</ItemInfoDetailReleaseDateContent>*/}
                        {/*</ItemInfoDetailReleaseDateWrapper>*/}
                    </ItemInfoDetailContent>
                </ItemInfoDetailWrapper>
                {
                    item.faqs.length > 0 ?
                        <div style={{marginTop: '10px'}}>
                            {item.faqs.map((faq, index) => (
                                <AccordionItem key={index} title={faq.title} content={faq.content} theme={theme}/>
                            ))}
                        </div>
                        : null
                }
            </ItemInfoWrapper>
        </>
    );
}

export default ItemInfo;
