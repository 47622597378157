import React from 'react';
import { ItemStyles } from '../styles/main.css';

const { PromoWrapper } = ItemStyles;

const Promo = ({ image }) => {

    return (
        <PromoWrapper src={image} alt="promo" />
    );
}

export default Promo;
