import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {Container, FiltersPageStyles, PromoPageStyles} from "../styles/main.css";
import closeIcon from "../assets/common/close.svg";
import Radio from "../components/Cart/Radio";
import {get} from "../lib/api";

const { FiltersWrapper, FiltersTitle, FiltersCloseBtn, FiltersCloseIcon, FiltersItemsWrapper, FiltersItemWrapper, FiltersItemTitle, FiltersOptionsWrapper } = FiltersPageStyles;

const Filters = () => {
    const { theme } = useTheme();

    const user = useSelector(state => state.userInfo);
    const { state } = useLocation();
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.text = "Применить фильтры"
    mainButton.enable();

    const [ platforms, setPlatforms ] = useState([]);
    const [ languages, setLanguages ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ prices, setPrices ] = useState([500, 1000, 2000]);
    const [ platformsActiveIndex, setPlatformsActiveIndex ] = useState(null);
    const [ languagesActiveIndex, setLanguagesActiveIndex ] = useState(null);
    const [ categoriesActiveIndex, setCategoriesActiveIndex ] = useState(null);
    const [ priceActiveIndex, setPriceActiveIndex ] = useState(null);

    const setFilters = () => {
        navigate(state.prevPage, {state: {searchValue: state.searchValue, platform: platforms[platformsActiveIndex], language: languages[languagesActiveIndex], category: categories[categoriesActiveIndex], price: prices[priceActiveIndex]}});
    }

    useEffect(() => {
        get('/items/filters', {token: user.token}).then((res) => {
            setPlatforms(res.platforms);
            setLanguages(res.languages);
            setCategories(res.categories);
            if (state?.platform) {
                setPlatformsActiveIndex(res.platforms.indexOf(state.platform));
            }
            if (state?.language) {
                setLanguagesActiveIndex(res.languages.indexOf(state.language));
            }
            if (state?.category) {
                setCategoriesActiveIndex(res.categories.indexOf(state.category));
            }
            if (state?.price) {
                setPriceActiveIndex(prices.indexOf(state.price));
            }
        });
    }, []);

    useEffect(() => {
        mainButton.onClick(setFilters);
        mainButton.color = theme.mainButtonColor;
        mainButton.textColor = theme.mainButtonTextColor;
        mainButton.show();
        return () => {
            mainButton.offClick(setFilters);
        }
    }, [platformsActiveIndex, languagesActiveIndex, categoriesActiveIndex, priceActiveIndex]);

    const handlePlatforms = (index) => {
        if (index === platformsActiveIndex) {
            setPlatformsActiveIndex(null);
        } else {
            setPlatformsActiveIndex(index);
        }
    }

    const platformActive = (index) => {
        return platformsActiveIndex === index;
    }

    const handleLanguages = (index) => {
        if (index === languagesActiveIndex) {
            setLanguagesActiveIndex(null);
        } else {
            setLanguagesActiveIndex(index);
        }
    }

    const languageActive = (index) => {
        return languagesActiveIndex === index;
    }

    const handleCategories = (index) => {
        if (index === categoriesActiveIndex) {
            setCategoriesActiveIndex(null);
        } else {
            setCategoriesActiveIndex(index);
        }
    }

    const categoryActive = (index) => {
        return categoriesActiveIndex === index;
    }

    const handlePrices = (index) => {
        if (index === priceActiveIndex) {
            setPriceActiveIndex(null);
        } else {
            setPriceActiveIndex(index);
        }
    }

    const priceActive = (index) => {
        return priceActiveIndex === index;
    }

    return (
        <>
            <Container>
                <FiltersWrapper colorTheme={theme}>
                    <FiltersTitle colorTheme={theme}>Фильтры</FiltersTitle>
                    <FiltersCloseBtn onClick={() => {navigate(-1)}}>
                        <FiltersCloseIcon src={closeIcon} alt="close"/>
                    </FiltersCloseBtn>
                </FiltersWrapper>
                <FiltersItemsWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Платформа</FiltersItemTitle>
                        <FiltersOptionsWrapper>
                            {platforms && platforms.map((platform, index) => {
                                return <Radio theme={theme} title={platform} active={platformActive(index)} handleActive={() => {handlePlatforms(index)} } />
                            })}
                        </FiltersOptionsWrapper>
                    </FiltersItemWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Язык</FiltersItemTitle>
                        <FiltersOptionsWrapper>
                            {languages && languages.map((language, index) => {
                                return <Radio theme={theme} title={language} active={languageActive(index)} handleActive={() => {handleLanguages(index)} } />
                            })}
                        </FiltersOptionsWrapper>
                    </FiltersItemWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Категория товара</FiltersItemTitle>
                        <FiltersOptionsWrapper>
                            {categories && categories.map((category, index) => {
                                return <Radio theme={theme} title={category} active={categoryActive(index)} handleActive={() => {handleCategories(index)} } />
                            })}
                        </FiltersOptionsWrapper>
                    </FiltersItemWrapper>
                    <FiltersItemWrapper>
                        <FiltersItemTitle colorTheme={theme}>Цена</FiltersItemTitle>
                        <FiltersOptionsWrapper>
                            {prices && prices.map((price, index) => {
                                return <Radio theme={theme} title={`до ${price} ₽`} active={priceActive(index)} handleActive={() => {handlePrices(index)} } />
                            })}
                        </FiltersOptionsWrapper>
                    </FiltersItemWrapper>
                </FiltersItemsWrapper>
            </Container>
        </>
    );
}

export default Filters;
