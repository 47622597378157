import styled from 'styled-components';

const whiteColor = '#FFF';
const blackColor = '#000';
const darkButtonColor = '#1C1C1C';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Rubik', sans-serif;
    padding: 0 18px 0 18px;

    @media (min-width: 0) and (max-width: 344px) {
        padding: 0 5px 0 5px;
    }
`;

const SearchStyles = {
    SearchWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        gap: 4px;
        align-items: center;
        margin-top: 20px;
    `,
    SearchInputWrapper: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background: ${darkButtonColor};
    `,
    SearchIcon: styled.img`
        width: 32px;
        height: 32px;
        margin-left: 4px;
    `,
    SearchInput: styled.input`
        width: 100%;
        height: 100%;
        margin-left: 6px;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 1.17px;
        color: ${whiteColor};
        background: transparent;
        border: none;
        outline: none;

        &::placeholder {
            color: #C9C9C9;
        }
    `,
    PersonalWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 51px;
        cursor: pointer;
    `,
    PersonalCircle: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background: ${darkButtonColor};
    `,
    PersonalIcon: styled.img`
        width: 22px;
        height: 22px;
    `,
    CartWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 51px;
        cursor: pointer;
    `,
    CartCircle: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background: ${darkButtonColor};

        &::after {
            display: ${props => props.cartCount > 0 ? 'flex' : 'none'};
            content: '${props => props.cartCount > 0 ? props.cartCount : ''}';
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -8px;
            right: -8px;
            color: ${blackColor};
            font-family: "Rubik", sans-serif;
            font-size: 10px;
            font-weight: 900;
            background: ${whiteColor};
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }
    `,
    CartIcon: styled.img`
        width: 22px;
        height: 22px;
    `,
}

const BestItemStyles = {
    BestItemWrapper: styled.div`
        position: relative;
        width: 220px;
        height: 220px;
        border-radius: 24px;
    `,
    BestItemImage: styled.img`
        width: 100%;
        height: 100%;
        border-radius: 24px;
        cursor: pointer;
        object-fit: cover;
    `,
    BestItemOverlay: styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-radius: 0 0 24px 24px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        padding: 2px 16px 2px 16px;
    `,
    BestItemTitle: styled.div`
        margin-top: 4px;
        display: flex;
        align-items: center;
    `,
    BestItemPrice: styled.span`
        color: #FFF;
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
        margin-right: 10px;
    `,
    BestItemDiscountWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 4px 1px 4px;
        border-radius: 4px;
        background: ${blackColor};
    `,
    BestItemDiscount: styled.span`
        color: #FFF;
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 14px;
        font-weight: 400;
    `,
    BestItemTagsWrapper: styled.div`
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 6px;
    `,
    BestItemTag: styled.span`
        color: ${whiteColor};
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 12px;
        font-weight: 400;
        padding: 3px 4px 1px 4px;
        border-radius: 4px;
        background: ${blackColor};
    `
}

const ItemStyles = {
    PromoWrapper: styled.img`
        margin-top: 10px;
        width: 168px;
        height: 276px;
        border-radius: 20px;
    `,
    ItemWrapper: styled.div`
        margin-top: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 168px;
        height: 276px;
        background-color: ${props => props.bgColor};
        border-radius: 20px;
    `,
    ItemImage: styled.img`
        width: 100%;
        height: 170px;
        border-radius: 20px 20px 0 0;
        cursor: pointer;
        object-fit: cover;
    `,
    ItemContent: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        padding: 0 5px 0 5px;
    `,
    ItemPriceWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
    ItemPrice: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 500;
        margin-right: 13px;
    `,
    ItemDiscountWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 4px 1px 4px;
        border-radius: 4px;
        background: ${props => props.colorTheme.titleColor};
    `,
    ItemDiscount: styled.span`
        color: ${props => props.colorTheme.cardColor};
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 14px;
        font-weight: 400;
    `,
    ItemTitle: styled.span`
        margin-top: 8px;
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: -0.84px;
    `,
    ItemCategory: styled.span`
        margin-top: 5px;
        color: ${props => props.colorTheme.subTitleColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
    `,
}

const CategoryStyles = {
    CategoryBackground: styled.div`
        width: 100%;
        border-radius: 12px;
        background-color: ${props => props.backgroundColor};
        margin-top: 20px;
        padding-top: 7px;
        padding-bottom: 24px;
    `,
    CategoryWrapper: styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    `,
    CategoryTitleWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
    CategoryIcon: styled.img`
        width: 28px;
        height: 28px;
        margin-right: 8px;
    `,
    CategoryTitle: styled.h2`
        font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: 700;
        background: ${props => props.colorTheme.titleColor};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `,
    ItemGridWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    `
}

const TabStyles = {
    TabWrapper: styled.div`
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 26px;
        border-radius: 6px;
        border: 1px solid ${props => props.colorTheme.tabBorderColor};
    `,
    TabItem: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 22px;
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 6px;
        ${props => props.isActive ? 'background: #435FFF;' : ''}
        cursor: pointer;
        transition: background 0.3s ease;
    `,
    TabItemTitle: styled.span`
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 500;
        ${props => props.isActive ? 'color: #FFF;' : `color: ${props.colorTheme.inputTextColor};`}
        transition: color 0.3s ease;
    `,
}

const ItemInfoStyles = {
    ItemInfoPreviewWrapper: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
    `,
    ItemInfoPreview: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `,
    ItemInfoWrapper: styled.div`
        margin-top: 375px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;
    `,
    ItemInfoMainWrapper: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
    `,
    ItemInfoMainContent: styled.div`
        width: 100%;
        padding-top: 10px;
        padding-bottom: 14px;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    ItemInfoMainWidgetsWrapper: styled.div`
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    ItemInfoMainFavouriteWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background: #717171;
        cursor: pointer;
    `,
    ItemInfoMainPriceWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
    ItemInfoMainPrice: styled.h1`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 32px;
        font-weight: 700;
    `,
    ItemInfoMainDiscountWrapper: styled.div`
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 8px 2px 8px;
        border-radius: 4px;
        background: ${props => props.colorTheme.titleColor};
    `,
    ItemInfoMainDiscount: styled.span`
        color: ${props => props.colorTheme.cardColor};
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 16px;
        font-weight: 400;
    `,
    ItemInfoMainTitleWrapper: styled.div`
        display: flex;
        align-items: center;
        margin-top: 3px;
        background-color: ${props => props.colorTheme.cardColor};
        padding-top: 10px;
        padding-bottom: 12px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    `,
    ItemInfoMainTitle: styled.h2`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 28px;
        font-weight: 500;
        letter-spacing: 0.56px;
    `,
    ItemInfoEditionContent: styled.div`
        margin-top: 10px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 14px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    ItemInfoEditionTitle: styled.h3`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    ItemInfoEditionItemsWrapper: styled.div`
        margin-top: 10px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        gap: 10px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    `,
    ItemInfoEditionItem: styled.div`
        padding: 10px 18px;
        flex-shrink: 0;
        scroll-snap-align: start;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        border: 2px solid ${props => props.isActive ? props.colorTheme.titleColor : props.colorTheme.inputTextColor};
        border-radius: 15px;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        -webkit-transition: 0.2s ease-in-out 0s;
        -moz-transition: 0.2s ease-in-out 0s;
        -o-transition: 0.2s ease-in-out 0s;
    `,
    ItemInfoEditionItemTitle: styled.span`
        color: ${props => props.isActive ? props.colorTheme.titleColor : props.colorTheme.inputTextColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    `,
    ItemInfoEditionItemPrice: styled.span`
        color: ${props => props.isActive ? props.colorTheme.titleColor : props.colorTheme.inputTextColor};
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
    `,
    ItemInfoDescriptionWrapper: styled.div`
        margin-top: 10px;
    `,
    ItemInfoDescriptionContent: styled.div`
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    ItemInfoDescription: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailWrapper: styled.div`
        margin-top: 10px;
    `,
    ItemInfoDetailContent: styled.div`
        width: 100%;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
    `,
    ItemInfoDetailPlatformWrapper: styled.div`
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        padding: 11px 18px 11px 18px;

        @media (min-width: 0) and (max-width: 344px) {
            padding: 0 5px 0 5px;
        }
    `,
    ItemInfoDetailPlatformTitle: styled.span`
        color: #717171;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailPlatformContentWrapper: styled.div`
        display: flex;
        align-items: center;
        gap: 6px;
    `,
    ItemInfoDetailPlatformContent: styled.span`
        color: ${props => props.colorTheme.cardColor};
        font-family: HelveticaNeueCyr, sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 8px 2px 8px;
        border-radius: 4px;
        background: ${props => props.colorTheme.titleColor};
    `,
    ItemInfoDetailLanguageWrapper: styled.div`
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3px;
        width: 100%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 11px 18px 11px 18px;

        @media (min-width: 0) and (max-width: 344px) {
            padding: 0 5px 0 5px;
        }
    `,
    ItemInfoDetailLanguageTitle: styled.span`
        color: #717171;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailLanguageContent: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: HelveticaNeueCyr, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    `,
    ItemInfoDetailReleaseDateWrapper: styled.div`
        background: ${props => props.colorTheme.cardColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3px;
        width: 100%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 11px 18px 11px 18px;

        @media (min-width: 0) and (max-width: 344px) {
            padding: 0 5px 0 5px;
        }
    `,
    ItemInfoDetailReleaseDateTitle: styled.span`
        color: #717171;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    `,
    ItemInfoDetailReleaseDateContent: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: HelveticaNeueCyr, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    `,
    ItemInfoFaqHeader: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 18px;
        font-weight: 700;
    `,
    ItemInfoFaqContent: styled.p`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 400;
    `,
}

const CartStyles = {
    CartWrapper: styled.div`
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    `,
    CartTitle: styled.h2`
        margin-bottom: 5px;
        font-family: "Rubik", sans-serif;
        font-size: 28px;
        font-weight: 700;
        color: ${props => props.colorTheme.titleColor};
    `,
    CartItemWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
        min-height: 60px;
        border-radius: 10px;
        background: ${props => props.colorTheme.cartItemBackgroundColor};
    `,
    CartItemLeftWrapper: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    CartItemLeftImage: styled.img`
        width: 70px;
        height: 70px;
        border-radius: 10px;
        object-fit: cover;
    `,
    CartItemLeftContent: styled.div`
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
    `,
    CartItemLeftPrice: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 500;
    `,
    CartItemLeftTitle: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.28px;
        margin-top: 2px;
    `,
    CartItemLeftCategory: styled.span`
        color: ${props => props.colorTheme.subTitleColor};
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.24px;
        margin-top: 2px;
    `,
    CartItemRightWrapper: styled.div`
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 6px;
        background: ${props => props.colorTheme.titleColor};
    `,
    CartItemRightIcon: styled.img`
        width: 24px;
        height: 24px;
    `,
    CartTotalWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
    `,
    CartTotalTitle: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.4px;
    `,
    CartTotalPriceWrapper: styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    `,
    CartTotalPrice: styled.span`
        font-family: "Rubik", sans-serif;
        font-size: 28px;
        font-weight: 700;
        color: ${props => props.colorTheme.titleColor};
    `,
    CartTotalPriceHelpText: styled.span`
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: red;
    `,
    CartOrderWrapper: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
        padding-bottom: 20px;
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    CartOrderAccountTitle: styled.span`
        margin-top: 14px;
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 20px;
        font-weight: 700;
    `,
    CartOrderAccountInput: styled.input`
        margin-top: 10px;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: ${props => props.colorTheme.inputColor};
        outline: none;
        padding-left: 10px;
        color: ${props => props.colorTheme.inputTextColor};
        font-family: HelveticaNeueCyr, sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
    `,
    CartOrderRadioWrapper: styled.div`
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    CartOrderRadioCircle: styled.div`
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid ${props => props.colorTheme.tabBorderColor};
        cursor: pointer;
    `,
    CartOrderRadio: styled.div`
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: ${props => props.isActive ? props.colorTheme.titleColor : props.colorTheme.radioDisableColor};
        transition: background 0.5s ease;
    `,
    CartOrderRadioTitle: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: HelveticaNeueCyr, sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.28px;
    `,
    CartPromoWrapper: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 12px;
        padding-bottom: ${props => props.isActive ? '20px' : '12px'};
        border-radius: 20px;
        background: ${props => props.colorTheme.cardColor};
    `,
    CartPromoInputWrapper: styled.div`
        margin-top: 10px;
        border-radius: 10px;
        background: ${props => props.colorTheme.inputColor};
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    CartPromoInput: styled.input`
        width: 100%;
        border-radius: 10px;
        height: 40px;
        background: ${props => props.colorTheme.inputColor};
        outline: none;
        padding-left: 10px;
        color: ${props => props.colorTheme.inputTextColor};
        font-family: HelveticaNeueCyr, sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.36px;
    `,
    CartPromoInputButton: styled.button`
        height: 40px;
        padding: 0 10px;
        background: ${props => props.colorTheme.titleColor};
        border-radius: 10px;
        border: 1px solid ${props => props.colorTheme.cardColor};
        color: ${props => props.colorTheme.cardColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    `,
    CartPromoInputButtonDisabled: styled.button`
        height: 40px;
        padding: 0 10px;
        background: ${props => props.colorTheme.radioDisableColor};
        border-radius: 10px;
        border: 1px solid ${props => props.colorTheme.cardColor};
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    `,
    CartPromoHelpText: styled.span`
        margin-top: 4px;
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 12px;
    `,
    CartPromoActiveText: styled.span`
        margin-top: 12px;
        color: ${props => props.colorTheme.titleColor};
        font-family: "Rubik", sans-serif;
        font-size: 12px;
    `,

}

const SearchPageStyles = {
    SearchHeaderWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        margin-top: 20px;
    `,
    SearchHeaderInputWrapper: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        flex-grow: 1;
        height: 40px;
        border-radius: 8px;
        background: ${darkButtonColor};
    `,
    SearchHeaderIcon: styled.img`
        width: 32px;
        height: 32px;
        margin-left: 4px;
    `,
    SearchHeaderInput: styled.input`
        width: 100%;
        height: 100%;
        margin-left: 6px;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 1.17px;
        color: ${whiteColor};
        background: transparent;
        border: none;
        outline: none;

        &::placeholder {
            color: #C9C9C9;
        }
    `,
    SearchHeaderFilters: styled.div`
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${darkButtonColor};
    `,
    SearchHeaderFilterIcon: styled.img`
        width: 28px;
        height: 28px;
    `,
    SearchItemsWrapper: styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Два столбца с равной шириной */
        gap: 10px;
    `,

    SearchItemWrapper: styled.div`
        margin-top: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-basis: calc(50vw - 20px); /* 50% ширины контейнера минус отступы */
        max-width: calc(50vw - 20px); /* Ограничение максимальной ширины */
        height: 276px;
        background-color: ${props => props.bgColor};
        border-radius: 20px;
    `,
}

const PromoPageStyles = {
    PromoHeaderWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 18px;
    `,
    PromoHeaderTitle: styled.h2`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    PromoHeaderFilters: styled.div`
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${darkButtonColor};
    `,
    PromoHeaderFilterIcon: styled.img`
        width: 28px;
        height: 28px;
    `,
    PromoItemsWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    `,
}

const FiltersPageStyles = {
    FiltersWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    `,
    FiltersTitle: styled.h2`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 24px;
        font-weight: 700;
    `,
    FiltersCloseBtn: styled.div`
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: ${darkButtonColor};
    `,
    FiltersCloseIcon: styled.img`
        width: 28px;
        height: 28px;
    `,
    FiltersItemsWrapper: styled.div`
        margin-top: 17px;
        display: flex;
        flex-direction: column;
        gap: 28px;
    `,
    FiltersItemWrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 2px;
    `,
    FiltersItemTitle: styled.h3`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    FiltersOptionsWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
    `,
}

const PersonalPageStyles = {
    PersonalTitle: styled.h2`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 28px;
        font-weight: 700;
    `,
    PersonalActionsWrapper: styled.div`
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 13px;
    `,
    PersonalAction: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        background: ${props => props.colorTheme.cardColor};
        height: 60px;
        padding: 0 10px;
    `,
    PersonalActionContent: styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    PersonalActionIcon: styled.img`
        width: 38px;
        height: 38px;
    `,
    PersonalActionText: styled.span`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 20px;
        font-weight: 400;
    `,
    PersonalActionArrow: styled.img`
        width: 24px;
        height: 24px;
    `
}

const FavouriteStyles = {
    FavouriteTitle: styled.h2`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 28px;
        font-weight: 700;
    `,
    FavouriteInfoWrapper: styled.div`
        margin-top: 15px;
        height: 88px;
        border-radius: 20px;
        background: #000;
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 0 15px;
    `,
    FavouriteInfoIcon: styled.img`
        width: 48px;
        height: 48px;
    `,
    FavouriteInfoText: styled.p`
        color: ${whiteColor};
        font-family: Rubik, sans-serif;
        font-size: 16px;
        font-weight: 400;
    `,
    ItemDiscountWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 4px 1px 4px;
        border-radius: 4px;
        background: ${props => props.colorTheme.titleColor};
    `,
    ItemDiscount: styled.span`
        color: ${props => props.colorTheme.cardColor};
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 10px;
        font-weight: 400;
    `,
    FavouritePriceWrapper: styled.div`
        display: flex;
        align-items: center;
        gap: 7px
    `
}

const HistoryStyles = {
    HistoryTitle: styled.h2`
        color: ${props => props.colorTheme.titleColor};
        font-family: Rubik, sans-serif;
        font-size: 28px;
        font-weight: 700;
    `,
    HistoryItemWrapper: styled.div`
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;
        padding-right: 10px;
        width: 100%;
        min-height: 60px;
        border-radius: 10px;
        background: ${props => props.colorTheme.cardColor};
    `,
    HistoryItemTime: styled.span`
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        color: ${props => props.colorTheme.subTitleColor};
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 500;
        white-space: pre-wrap;
    `
}

export {
    Container,
    SearchStyles,
    BestItemStyles,
    CategoryStyles,
    ItemStyles,
    TabStyles,
    ItemInfoStyles,
    CartStyles,
    SearchPageStyles,
    PromoPageStyles,
    FiltersPageStyles,
    PersonalPageStyles,
    FavouriteStyles,
    HistoryStyles,
};
