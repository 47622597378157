import AppRoutes from './AppRoutes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import loaderImage from './assets/common/loader.svg';
import {addUser, setCart} from './redux-state/reducers/UserInfo';
import { lightTheme, darkTheme, } from './styles/themes';
import { post } from './lib/api';

function App() {
    const tg = window.Telegram.WebApp;

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    if (tg) {
        tg.expand();
        tg.enableClosingConfirmation();
    }

    useEffect(() => {
        let _mounted = true;
        if (tg.initData) {
            setLoading(true);
            post('/users/loginByInitData', {},
                decodeURIComponent(tg.initData)).then(data => {
                    _mounted && setLoading(false);
                    const user = {
                        token: data.token,
                        name: data.user.name,
                        theme: tg.colorScheme
                    }
                    dispatch(addUser(user));
                }
            ).catch(err => {
                console.log(err);
                tg.close();
                }
            )
        }
        return () => _mounted = false
    }, [tg.initData]);

    if (loading) {
        return <div
            style={{textAlign: 'center', padding: '20px', height: '100vh', display: 'flex', justifyContent: 'center'}}>
            <img src={loaderImage} alt='Loading' style={{width: 64}}/>
        </div>
    }

    if (tg.colorScheme === 'dark') {
        tg.setHeaderColor(darkTheme.backgroundColor);
        document.body.style.backgroundColor = darkTheme.backgroundColor;
    } else {
        tg.setHeaderColor(lightTheme.backgroundColor);
        document.body.style.backgroundColor = lightTheme.backgroundColor;
    }

    return (
        <>
            <AppRoutes/>
        </>
    );
}

export default App;
