import React, {useEffect, useState} from 'react';
import {CartStyles, Container} from '../../styles/main.css';
import {useDispatch} from 'react-redux';
import {deleteItem} from '../../redux-state/reducers/UserInfo';
import Trash from "../Icons/Trash";

const { CartTitle, CartItemWrapper, CartItemLeftWrapper, CartItemLeftImage, CartItemLeftContent, CartItemLeftPrice, CartItemLeftTitle, CartItemLeftCategory, CartItemRightWrapper, CartItemRightIcon, CartTotalWrapper, CartTotalTitle, CartTotalPriceWrapper, CartTotalPrice, CartTotalPriceHelpText } = CartStyles;

const Header = ({ cart, totalAmount, theme }) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const dispatch = useDispatch();
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        setCartItems(cart.items);
    }, [cart]);

    const deleteItemCart = (item) => {
        dispatch(deleteItem(item));
    }
    return (
        <>
            <Container>
                <CartTitle colorTheme={theme}>Корзина</CartTitle>
                {
                    cartItems.map((item, index) => {
                        return (
                            <CartItemWrapper colorTheme={theme} key={index}>
                                <CartItemLeftWrapper>
                                    <CartItemLeftImage src={item.image} alt={item.title} />
                                    <CartItemLeftContent colorTheme={theme}>
                                        <CartItemLeftPrice colorTheme={theme}>{formatPrice(item.price)} ₽</CartItemLeftPrice>
                                        <CartItemLeftTitle colorTheme={theme}>{item.title}</CartItemLeftTitle>
                                        <CartItemLeftCategory colorTheme={theme}>{item.category}</CartItemLeftCategory>
                                    </CartItemLeftContent>
                                </CartItemLeftWrapper>
                                <CartItemRightWrapper colorTheme={theme} onClick={() => deleteItemCart(item)}>
                                    <Trash color={theme.cardColor} />
                                </CartItemRightWrapper>
                            </CartItemWrapper>
                        )
                    })
                }
                <CartTotalWrapper colorTheme={theme}>
                    <CartTotalTitle colorTheme={theme}>Итого:</CartTotalTitle>
                    <CartTotalPriceWrapper>
                        <CartTotalPrice colorTheme={theme}>{formatPrice(totalAmount)} ₽</CartTotalPrice>
                        {
                            totalAmount < 100 &&
                            <CartTotalPriceHelpText colorTheme={theme}>Минимальная сумма заказа 100₽</CartTotalPriceHelpText>
                        }
                    </CartTotalPriceWrapper>
                </CartTotalWrapper>
            </Container>
        </>
    );
}

export default Header;
