import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useNavigate} from "react-router-dom";
import {Container, FavouriteStyles, CartStyles} from "../styles/main.css";
import favouriteStarIcon from '../assets/favourite_star.svg';
import Radio from "../components/Cart/Radio";
import {get} from "../lib/api";
import Trash from "../components/Icons/Trash";
import {useSelector} from "react-redux";

const { FavouriteTitle, FavouriteInfoWrapper, FavouriteInfoIcon, FavouriteInfoText, ItemDiscountWrapper, ItemDiscount, FavouritePriceWrapper } = FavouriteStyles;
const { CartItemWrapper, CartItemLeftWrapper, CartItemLeftImage, CartItemLeftContent, CartItemLeftPrice, CartItemLeftTitle, CartItemLeftCategory, CartItemRightWrapper } = CartStyles;

const Favourite = () => {
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.text = "Перейти в каталог"
    mainButton.enable();

    const goCatalog = () => {
        navigate('/');
    }

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const [ discountActive, setDiscountActive ] = useState(false);
    const [ items, setItems ] = useState([]);
    const [ displayItems, setDisplayItems ] = useState([]);

    const handleDiscount = () => {
        if (!discountActive) {
            setDisplayItems(items.filter(item => item.discount > 0));
        } else {
            setDisplayItems(items);
        }
        setDiscountActive(!discountActive);
    }

    useEffect(() => {
        get('/users/getFavourites', {token: user.token}).then((res) => {
            console.log(res.favourites)
            setItems(res.favourites);
            if (discountActive) {
                setDisplayItems(res.favourites.filter(item => item.discount > 0));
            } else {
                setDisplayItems(res.favourites);
            }
        });
    }, []);

    const getInfoText = () => {
        return (
            <>
                <p>Вы получите уведомление</p>
                <p>от бота, если цена на товар</p>
                <p>снизится</p>
            </>
        )

    }

    const deleteItemFavourite = (item) => {
        get('/users/switchFavourite', {token: user.token, item_id: item.id}).then((res) => {
            if (res.success) {
                let tempItems = [...items];
                tempItems = tempItems.filter(i => i.id !== item.id);
                setItems(tempItems);
                if (discountActive) {
                    setDisplayItems(tempItems.filter(item => item.discount > 0));
                } else {
                    setDisplayItems(tempItems);
                }
            }
        });
    }

    useEffect(() => {
        mainButton.color = theme.mainButtonColor;
        mainButton.textColor = theme.mainButtonTextColor;
        mainButton.onClick(goCatalog);
        mainButton.show();
        return () => {
            mainButton.offClick(goCatalog);
        }
    }, []);

    return (
        <>
            <Container>
                <FavouriteTitle colorTheme={theme}>Избранное</FavouriteTitle>
                <FavouriteInfoWrapper colorTheme={theme}>
                    <FavouriteInfoIcon src={favouriteStarIcon} alt="favourite"/>
                    <FavouriteInfoText colorTheme={theme}>{getInfoText()}</FavouriteInfoText>
                </FavouriteInfoWrapper>
                <Radio theme={theme} title="Только со скидкой" active={discountActive} handleActive={handleDiscount} />
                <div style={{marginTop: "7px"}}>
                    {
                        displayItems && displayItems.map((item, index) => {
                            return (
                                <CartItemWrapper colorTheme={theme} key={index}>
                                    <CartItemLeftWrapper>
                                        <CartItemLeftImage src={item.image} alt={item.title} />
                                        <CartItemLeftContent colorTheme={theme}>
                                            <FavouritePriceWrapper>
                                                <CartItemLeftPrice colorTheme={theme}>{formatPrice(item.price)} ₽</CartItemLeftPrice>
                                                {
                                                    item.discount > 0 &&
                                                    <ItemDiscountWrapper colorTheme={theme}>
                                                        <ItemDiscount colorTheme={theme}>-{item.discount}%</ItemDiscount>
                                                    </ItemDiscountWrapper>
                                                }
                                            </FavouritePriceWrapper>
                                            <CartItemLeftTitle colorTheme={theme}>{item.title}</CartItemLeftTitle>
                                            <CartItemLeftCategory colorTheme={theme}>{item.category}</CartItemLeftCategory>
                                        </CartItemLeftContent>
                                    </CartItemLeftWrapper>
                                    <CartItemRightWrapper colorTheme={theme} onClick={() => deleteItemFavourite(item)}>
                                        <Trash color={theme.cardColor} />
                                    </CartItemRightWrapper>
                                </CartItemWrapper>
                            )
                        })
                    }
                </div>
            </Container>
        </>
    );
}


export default Favourite;
