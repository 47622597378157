import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useNavigate} from "react-router-dom";
import { CartStyles, Container, FavouriteStyles, HistoryStyles } from '../styles/main.css';
import { get } from '../lib/api';
import { useSelector } from 'react-redux';

const { HistoryTitle, HistoryItemWrapper, HistoryItemTime } = HistoryStyles;
const { CartItemLeftWrapper, CartItemLeftImage, CartItemLeftContent, CartItemLeftPrice, CartItemLeftTitle, CartItemLeftCategory } = CartStyles;
const { ItemDiscountWrapper, ItemDiscount, FavouritePriceWrapper } = FavouriteStyles;

const History = () => {
    const [ items, setItems ] = useState([]);
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.text = "Перейти в каталог"
    mainButton.enable();

    const goCatalog = () => {
        navigate('/');
    }

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    useEffect(() => {
        mainButton.color = theme.mainButtonColor;
        mainButton.textColor = theme.mainButtonTextColor;
        mainButton.onClick(goCatalog);
        mainButton.show();
        return () => {
            mainButton.offClick(goCatalog);
        }
    }, []);

    useEffect(() => {
        get('/users/getHistory', {token: user.token}).then((res) => {
            setItems(res.items);
        });
    }, []);

    return (
        <>
            <Container>
                <HistoryTitle colorTheme={theme}>История покупок</HistoryTitle>
                <div style={{marginTop: "15px"}}>
                    {
                        items.map((item, index) => {
                            return (
                                <HistoryItemWrapper colorTheme={theme} key={index}>
                                    <CartItemLeftWrapper>
                                        <CartItemLeftImage src={item.image} alt={item.title}/>
                                        <CartItemLeftContent colorTheme={theme}>
                                            <FavouritePriceWrapper>
                                                <CartItemLeftPrice
                                                    colorTheme={theme}>{formatPrice(item.price)} ₽</CartItemLeftPrice>
                                                {
                                                    item.discount > 0 &&
                                                    <ItemDiscountWrapper colorTheme={theme}>
                                                        <ItemDiscount colorTheme={theme}>-{item.discount}%</ItemDiscount>
                                                    </ItemDiscountWrapper>
                                                }
                                            </FavouritePriceWrapper>
                                            <CartItemLeftTitle colorTheme={theme}>{item.title}</CartItemLeftTitle>
                                            <CartItemLeftCategory colorTheme={theme}>{item.category}</CartItemLeftCategory>
                                        </CartItemLeftContent>
                                    </CartItemLeftWrapper>
                                    <HistoryItemTime colorTheme={theme}>{`${item.date}\n${item.time}`}</HistoryItemTime>
                                </HistoryItemWrapper>
                            )
                        })
                    }
                </div>
            </Container>
        </>
    );
}

export default History;
