import React from 'react';
import { ItemInfoStyles } from '../../styles/main.css';


const { ItemInfoEditionItem, ItemInfoEditionItemTitle, ItemInfoEditionItemPrice} = ItemInfoStyles;

const ItemEdition = ({ title, price, theme, isActive, onClick }) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    return (
        <ItemInfoEditionItem onClick={onClick} colorTheme={theme} isActive={isActive}>
            <ItemInfoEditionItemTitle colorTheme={theme} isActive={isActive}>{title}</ItemInfoEditionItemTitle>
            <ItemInfoEditionItemPrice colorTheme={theme} isActive={isActive}>{formatPrice(price)} ₽</ItemInfoEditionItemPrice>
        </ItemInfoEditionItem>
    )
}

export default ItemEdition;