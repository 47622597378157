import {BestItemStyles} from '../../styles/main.css';

const {
    BestItemWrapper,
    BestItemImage,
    BestItemOverlay,
    BestItemTitle,
    BestItemPrice,
    BestItemDiscountWrapper,
    BestItemDiscount,
    BestItemTagsWrapper,
    BestItemTag
} = BestItemStyles;

const Item = ({image, price, discount, platforms, tags}) => {
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return (
        <BestItemWrapper>
            <BestItemImage src={image} alt="Item"/>
            <BestItemOverlay>
                <BestItemTitle>
                    <BestItemPrice>{formatPrice(price)} ₽</BestItemPrice>
                    {
                        discount > 0 &&
                        <BestItemDiscountWrapper>
                            <BestItemDiscount>-{discount}%</BestItemDiscount>
                        </BestItemDiscountWrapper>
                    }
                </BestItemTitle>
                <BestItemTagsWrapper>
                    {[...platforms, ...tags].map((tag, index) => (
                        <BestItemTag key={index}>{tag}</BestItemTag>
                    ))}
                </BestItemTagsWrapper>
            </BestItemOverlay>
        </BestItemWrapper>
    );
}


export default Item;
