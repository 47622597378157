import React from 'react';
import { SearchStyles } from '../../styles/main.css';
import config from '../../config'

const { SearchWrapper, SearchInputWrapper, SearchIcon, SearchInput, CartWrapper, CartCircle, CartIcon, PersonalWrapper, PersonalCircle, PersonalIcon } = SearchStyles;

const Search = ({ colorTheme, cartCount, goToCart, goToSearch, goToPersonal }) => {
    return (
        <>
            <SearchWrapper>
                <SearchInputWrapper onClick={goToSearch}>
                    <SearchIcon src={`${config.STATIC_BASE_URL}/search.svg`} alt="search" />
                    <SearchInput type="text" placeholder="Найти игру или подписку" colorTheme={colorTheme} />
                </SearchInputWrapper>
                <PersonalWrapper onClick={goToPersonal}>
                    <PersonalCircle>
                        <PersonalIcon src={`${config.STATIC_BASE_URL}/account.svg`} alt="account" />
                    </PersonalCircle>
                </PersonalWrapper>
                <CartWrapper onClick={goToCart}>
                    <CartCircle cartCount={cartCount}>
                        <CartIcon src={`${config.STATIC_BASE_URL}/cart.svg`} alt="cart" />
                    </CartCircle>
                </CartWrapper>
            </SearchWrapper>
        </>
    );
}

export default Search;
