const Star = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path
                d="M29.2655 26.5484C28.258 26.7892 27.2063 26.9166 26.1251 26.9166C18.6923 26.9166 12.6668 20.8911 12.6668 13.4583C12.6668 12.6928 12.7307 11.9423 12.8534 11.2116C12.7961 11.2668 12.7369 11.3175 12.6746 11.3648C12.2303 11.7021 11.6769 11.8273 10.5702 12.0777L9.56269 12.3057C5.6682 13.1869 3.72095 13.6274 3.25767 15.1172C2.79438 16.6071 4.1219 18.1594 6.77691 21.264L7.46379 22.0672C8.21825 22.9494 8.59549 23.3906 8.7652 23.9363C8.93491 24.4821 8.87788 25.0706 8.7638 26.2478L8.65997 27.3194C8.25856 31.4618 8.05786 33.5329 9.27074 34.4536C10.4836 35.3743 12.3068 34.535 15.9533 32.856L16.8966 32.4216C17.9328 31.9445 18.4508 31.7061 19.0001 31.7061C19.5493 31.7061 20.0674 31.9445 21.1035 32.4216L22.0469 32.856C25.6933 34.535 27.5165 35.3743 28.7295 34.4536C29.9423 33.5329 29.7416 31.4618 29.3402 27.3194L29.2655 26.5484Z"
                fill={color}/>
            <path opacity="0.5"
                  d="M14.4923 8.56326L13.9735 9.49402C13.4036 10.5164 13.1186 11.0276 12.6743 11.3649C12.7367 11.3175 12.7959 11.2668 12.8532 11.2117C12.7304 11.9423 12.6665 12.6928 12.6665 13.4583C12.6665 20.8911 18.6919 26.9167 26.1249 26.9167C27.2062 26.9167 28.2576 26.7892 29.2653 26.5484L29.2361 26.2479C29.122 25.0707 29.065 24.4821 29.2347 23.9363C29.4045 23.3906 29.7816 22.9495 30.5361 22.0672L31.2231 21.264C33.878 18.1594 35.2055 16.6069 34.7422 15.1172C34.2789 13.6275 32.3317 13.1869 28.4372 12.3057L27.4297 12.0777C26.323 11.8273 25.7696 11.7021 25.3253 11.3649C24.881 11.0276 24.596 10.5164 24.0262 9.49404L23.5073 8.56324C21.5017 4.96551 20.499 3.16666 18.9999 3.16666C17.5006 3.16666 16.4979 4.96553 14.4923 8.56326Z"
                  fill={color}/>
        </svg>
    );
}

export default Star;