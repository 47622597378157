import {PersonalPageStyles} from "../../styles/main.css";
import Arrow from "../Icons/Arrow";

const { PersonalAction, PersonalActionContent, PersonalActionText } = PersonalPageStyles;
const PersonalItem = ({ colorTheme, icon: Icon, text, onClick }) => {
    return (
        <>
            <PersonalAction colorTheme={colorTheme} onClick={onClick}>
                <PersonalActionContent colorTheme={colorTheme}>
                    <Icon color={colorTheme.titleColor} />
                    <PersonalActionText colorTheme={colorTheme}>{text}</PersonalActionText>
                </PersonalActionContent>
                <Arrow color={colorTheme.titleColor} />
            </PersonalAction>
        </>
    );
}

export default PersonalItem;