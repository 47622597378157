import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Item from './pages/Item';
import Cart from './pages/Cart';
import Search from './pages/Search';
import Personal from './pages/Personal';
import Favourite from './pages/Favourite';
import Promo from './pages/Promo';
import Filters from './pages/Filters';
import History from './pages/History';
import SuccessPayment from './pages/SuccessPayment';

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/item/:itemId" element={<Item />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/personal" element={<Personal />} />
            <Route path="/search" element={<Search />} />
            <Route path="/promo/:promoId" element={<Promo />} />
            <Route path="/favourite" element={<Favourite />} />
            <Route path="/history" element={<History />} />
            <Route path="/filters" element={<Filters />} />
            <Route path="/success_payment" element={<SuccessPayment />} />
        </Routes>
    );
}

export default AppRoutes;
