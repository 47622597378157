import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useDispatch, useSelector} from "react-redux";
import {get, post} from "../lib/api";
import ItemInfo from "../components/ItemInfo/ItemInfo";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {addItem} from "../redux-state/reducers/UserInfo";

const Item = () => {
    const [ item, setItem ] = useState();
    const [ items, setItems ] = useState();
    const { theme } = useTheme();
    const { state } = useLocation();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;
    const { itemId } = useParams();

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log(state);

    const goToBack = () => {
        if (state?.prevPage) {
            navigate(state.prevPage, {state: {prevPage: `/item/${itemId}`, searchValue: state?.searchValue, platform: state?.platform, language: state?.language, category: state?.category, price: state?.price}});
        } else {
            navigate(-1);
        }
    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.enable();

    const goToCart = () => {
        navigate('/cart');
    }

    const addToCart = () => {
        dispatch(addItem(item));
    }

    useEffect(() => {
        post('/users/updateCart', {token: user.token}, {cart: cart}).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }, [cart]);

    useEffect(() => {
        if (item && cart.items.find(cartItem => cartItem.id === item.id)) {
            mainButton.text = "Перейти в корзину"
            mainButton.color = "#435FFF"
            mainButton.textColor = "#FFFFFF"
            mainButton.onClick(goToCart);
            mainButton.show();
            return () => {
                mainButton.offClick(goToCart);
            }
        } else {
            if (item) {
                mainButton.textColor = theme.mainButtonTextColor;
                mainButton.color = theme.mainButtonColor;
                mainButton.text = "Добавить в корзину"
                mainButton.onClick(addToCart);
                mainButton.show();
                return () => {
                    mainButton.offClick(addToCart);
                }
            }
        }
    }, [cart, item]);

    useEffect(() => {
        get('/items/getItem', {token: user.token, item_id: itemId}).then((res) => {
            setItems(res.items);
            setItem(res.items.find(item => item.id === Number(itemId)));
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    return (
        <>
            {
                item && <ItemInfo theme={theme} item={item} setItem={setItem} items={items} user={user} />
            }
        </>
    );
}

export default Item;
