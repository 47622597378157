const Trash = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.17188 4C9.58203 2.83594 10.6953 2 12 2C13.3047 2 14.418 2.83594 14.8281 4" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M20.5 6H3.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M18.832 8.5L18.375 15.3984C18.1953 18.0547 18.1094 19.3828 17.2422 20.1914C16.3789 21 15.0469 21 12.3867 21H11.6133C8.95312 21 7.62109 21 6.75781 20.1914C5.89062 19.3828 5.80469 18.0547 5.625 15.3984L5.16797 8.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M9.5 11L10 16" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14.5 11L14 16" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
}

export default Trash;