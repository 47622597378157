import React, {useEffect, useRef, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import {useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {Container, SearchPageStyles} from "../styles/main.css";
import config from "../config";
import {get} from "../lib/api";
import filtersIcon from "../assets/common/filters.svg";
import { useInView } from 'react-intersection-observer';
import loaderImage from '../assets/common/loader.svg';
import Item from '../components/Search/Item';

const { SearchHeaderWrapper, SearchHeaderInputWrapper, SearchHeaderInput, SearchHeaderIcon , SearchHeaderFilters, SearchHeaderFilterIcon, SearchItemsWrapper} = SearchPageStyles;

const Cart = () => {
    const [offset, setOffset] = useState(0);
    const [searchItems, setSearchItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const { theme } = useTheme();
    const { state } = useLocation();
    const { ref, inView } = useInView()
    const user = useSelector(state => state.userInfo);
    const [searchValue, setSearchValue] = useState(state?.searchValue || '');

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const getItems = (clear = false) => {
        setLoading(true);
        if (clear) {
            setOffset(0);
        }
        const params = {
            token: user.token,
            q: searchValue,
            offset: clear ? 0 : offset
        }
        if (state?.platform) {
            params.platform = state?.platform
        }
        if (state?.language) {
            params.language = state?.language
        }
        if (state?.category) {
            params.category = state?.category
        }
        if (state?.price) {
            params.price = state?.price
        }
        get('/items/search', params).then((res) => {
            return res.items
        }).then((items) => {
            if (clear) {
                setSearchItems(items);
            } else {
                setSearchItems([...searchItems, ...items]);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        getItems(true);
        setOffset(10);

    }, [searchValue]);

    const loadMore = () => {
        getItems();
        setOffset(offset + 10);
    }

    const inputRef = useRef();
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();

    const goToBack = () => {
        navigate('/');

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);

    const mainButton = tg.MainButton;
    mainButton.hide();

    const goToFilters = () => {
        navigate('/filters', {state: {prevPage: '/search', searchValue: searchValue, platform: state?.platform, language: state?.language, category: state?.category, price: state?.price}});
    }

    const goToItem = (itemId) => {
        navigate(`/item/${itemId}`, {state: {prevPage: '/search', searchValue: searchValue, platform: state?.platform, language: state?.language, category: state?.category, price: state?.price}});
    }

    useEffect(() => {
        inputRef.current.focus();
        inputRef.current.click();
    }, []);

    useEffect(() => {
        if (inView) {
            loadMore()
        }
    }, [inView]);

    return (
        <>
            <Container>
                <SearchHeaderWrapper colorTheme={theme}>
                    <SearchHeaderInputWrapper colorTheme={theme}>
                        <SearchHeaderIcon src={`${config.STATIC_BASE_URL}/search.svg`} alt="filters"/>
                        <SearchHeaderInput
                            colorTheme={theme}
                            value={searchValue}
                            onChange={handleSearch}
                            placeholder="Найти игру или подписку"
                            ref={inputRef}
                        />
                    </SearchHeaderInputWrapper>
                    <SearchHeaderFilters colorTheme={theme} onClick={goToFilters}>
                        <SearchHeaderFilterIcon src={filtersIcon} alt="filters"/>
                    </SearchHeaderFilters>
                </SearchHeaderWrapper>
                <SearchItemsWrapper>
                    {searchItems && searchItems.map((item, index) => {
                        const innerRef = index === searchItems.length - 1 ? ref : undefined
                        return (
                            <div ref={innerRef} onClick={() => goToItem(item.id)} key={index}>
                                <Item image={item.image} price={item.price} discount={item.discount} title={item.title}
                                      category={item.category} theme={theme} bgColor={theme.cardColor}/>
                            </div>
                        )
                    })}
                </SearchItemsWrapper>
                {
                    loading &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <img src={loaderImage} alt="Loading" style={{width: 64}}/>
                    </div>
                }
            </Container>
        </>
    );
}

export default Cart;
