import React, {useEffect, useState} from 'react';
import {CartStyles, Container} from '../../styles/main.css';
import Tab from "./Tab";
import Radio from "./Radio";
import {get} from "../../lib/api";
import {useDispatch} from "react-redux";
import {setUserDiscount, setUserPromoCode} from "../../redux-state/reducers/UserInfo";

const { CartOrderWrapper, CartOrderAccountTitle, CartOrderAccountInput, CartPromoWrapper, CartPromoInputWrapper, CartPromoInput, CartPromoInputButton, CartPromoActiveText, CartPromoHelpText } = CartStyles;

const Details = ({ theme, user, accountLinkValue, accountLoginValue, accountPasswordValue, promoValue, setAccountLinkValue, setAccountLoginValue, setAccountPasswordValue, setPromoValue, discount, setDiscount, isLink, isLogin, isPassword, setEnable }) => {

    const [promoActive, setPromoActive] = useState(false);
    const [helpText, setHelpText] = useState(null);
    const [disableButton, setDisableButton] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        discount && discount > 0 ? setDisableButton(true) : setDisableButton(false);
    }, [promoValue, discount]);

    const activatePromo = () => {
        get('/promo/getPromo', {token: user.token, promo: promoValue }).then((res) => {
            if (res.success) {
                setHelpText('Промокод на скидку ' + res.discount + '% активирован');
                setDiscount(res.discount);
                dispatch(setUserDiscount(res.discount));
                dispatch(setUserPromoCode(promoValue));
                setPromoValue('');
                setPromoActive(false);
            } else {
                setHelpText('Промокод не найден');
            }
        })
    }

    const handlePromo = () => {
        setPromoActive(!promoActive);
    }

    useEffect(() => {
        let enable = true;

        if (isLink && enable) {
            enable = accountLinkValue.length > 0;
        }
        if (isLogin && enable) {
            enable = accountLoginValue.length > 0;
        }
        if (isPassword && enable) {
            enable = accountPasswordValue.length > 0;
        }
        setEnable(enable);

    }, [accountLinkValue, accountLoginValue, accountPasswordValue, isLink, isLogin, isPassword]);


    return (
        <>
            <CartOrderWrapper colorTheme={theme}>
                <Container>
                    {
                        (isLink || isLogin || isPassword) &&
                        <CartOrderAccountTitle colorTheme={theme}>Данные аккаунта</CartOrderAccountTitle>
                    }
                    {
                        isLink &&
                        <CartOrderAccountInput colorTheme={theme} type="text" placeholder="Введите ссылку на Steam" value={accountLinkValue} onChange={(e) => setAccountLinkValue(e.target.value)} />
                    }
                    {
                        isLogin &&
                        <CartOrderAccountInput colorTheme={theme} type="text" placeholder="Введите логин от аккаунта" value={accountLoginValue} onChange={(e) => setAccountLoginValue(e.target.value)} />
                    }
                    {
                        isPassword &&
                        <CartOrderAccountInput colorTheme={theme} type="password" placeholder="Введите пароль от аккаунта" value={accountPasswordValue} onChange={(e) => setAccountPasswordValue(e.target.value)} />
                    }
                    {/*<CartOrderAccountTitle colorTheme={theme}>Платежные данные</CartOrderAccountTitle>*/}
                    {/*<CartOrderAccountInput colorTheme={theme} type="email" placeholder="Введите Email для чека" value={accountCheckoutEmailValue} onChange={(e) => setAccountCheckoutEmailValue(e.target.value)} />*/}
                </Container>
            </CartOrderWrapper>
            <CartPromoWrapper colorTheme={theme} isActive={promoActive}>
                <Container>
                    {
                        discount > 0 ?
                            <CartPromoActiveText colorTheme={theme}>Промокод на скидку {discount}% активирован</CartPromoActiveText>
                            :
                            <Radio theme={theme} title="У меня есть промокод" active={promoActive} handleActive={handlePromo} />
                    }
                    {
                        promoActive &&
                        <>
                            <CartPromoInputWrapper colorTheme={theme}>
                                <CartPromoInput colorTheme={theme} type="text" placeholder="Промокод" value={promoValue} onChange={(e) => setPromoValue(e.target.value)} />
                                {
                                    !promoValue || disableButton ?
                                        <></>
                                        :
                                        <CartPromoInputButton onClick={activatePromo} colorTheme={theme}>Активировать</CartPromoInputButton>
                                }
                            </CartPromoInputWrapper>
                            {
                                helpText &&
                                <CartPromoHelpText colorTheme={theme}>{helpText}</CartPromoHelpText>
                            }
                        </>
                    }
                </Container>
            </CartPromoWrapper>
        </>
    );
}

export default Details;
