import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    token: null,
    name: null,
    theme: null,
    cart: {
        items: []
    },
    discount: 0,
    promoCode: null
};


const infoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.token = action.payload.token;
            state.name = action.payload.name;
            state.theme = action.payload.theme;
        },
        addItem: (state, action) => {
            state.cart.items.push(action.payload);
        },
        deleteItem: (state, action) => {
            state.cart.items = state.cart.items.filter(item => item.id !== action.payload.id);
        },
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        clearCart: (state) => {
            state.cart = {
                items: []
            };
        },
        setUserDiscount: (state, action) => {
            state.discount = action.payload
        },
        setUserPromoCode: (state, action) => {
            state.promoCode = action.payload
        }
    }
});


export const { addUser, addItem, deleteItem, clearCart, setCart, setUserDiscount, setUserPromoCode } = infoSlice.actions;
export default infoSlice.reducer;
