const List = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path opacity="0.5"
                  d="M11.4713 3.16666H26.5287C28.3637 3.16666 29.2811 3.16666 30.021 3.42412C31.4241 3.91234 32.5256 5.04637 32.9998 6.4908C33.25 7.25253 33.25 8.19699 33.25 10.0859V32.2591C33.25 33.618 31.6904 34.339 30.7038 33.4362C30.1242 32.9058 29.2508 32.9058 28.6712 33.4362L27.9062 34.1362C26.8904 35.0658 25.3596 35.0658 24.3438 34.1362C23.3279 33.2066 21.7971 33.2066 20.7812 34.1362C19.7654 35.0658 18.2346 35.0658 17.2188 34.1362C16.2029 33.2066 14.6721 33.2066 13.6562 34.1362C12.6404 35.0658 11.1096 35.0658 10.0938 34.1362L9.32884 33.4362C8.74923 32.9058 7.87577 32.9058 7.29616 33.4362C6.30958 34.339 4.75 33.618 4.75 32.2591V10.0859C4.75 8.19699 4.75 7.25253 5.00009 6.4908C5.47434 5.04637 6.57593 3.91234 7.97903 3.42412C8.71896 3.16666 9.63639 3.16666 11.4713 3.16666Z"
                  fill={color}/>
            <path
                d="M11.0833 10.6875C10.4274 10.6875 9.89575 11.2192 9.89575 11.875C9.89575 12.5308 10.4274 13.0625 11.0833 13.0625H11.8749C12.5308 13.0625 13.0624 12.5308 13.0624 11.875C13.0624 11.2192 12.5308 10.6875 11.8749 10.6875H11.0833Z"
                fill={color}/>
            <path
                d="M16.625 10.6875C15.9692 10.6875 15.4375 11.2192 15.4375 11.875C15.4375 12.5308 15.9692 13.0625 16.625 13.0625H26.9167C27.5725 13.0625 28.1042 12.5308 28.1042 11.875C28.1042 11.2192 27.5725 10.6875 26.9167 10.6875H16.625Z"
                fill={color}/>
            <path
                d="M11.0833 16.2292C10.4274 16.2292 9.89575 16.7608 9.89575 17.4167C9.89575 18.0725 10.4274 18.6042 11.0833 18.6042H11.8749C12.5308 18.6042 13.0624 18.0725 13.0624 17.4167C13.0624 16.7608 12.5308 16.2292 11.8749 16.2292H11.0833Z"
                fill={color}/>
            <path
                d="M16.625 16.2292C15.9692 16.2292 15.4375 16.7608 15.4375 17.4167C15.4375 18.0725 15.9692 18.6042 16.625 18.6042H26.9167C27.5725 18.6042 28.1042 18.0725 28.1042 17.4167C28.1042 16.7608 27.5725 16.2292 26.9167 16.2292H16.625Z"
                fill={color}/>
            <path
                d="M11.0833 21.7708C10.4274 21.7708 9.89575 22.3025 9.89575 22.9583C9.89575 23.6141 10.4274 24.1458 11.0833 24.1458H11.8749C12.5308 24.1458 13.0624 23.6141 13.0624 22.9583C13.0624 22.3025 12.5308 21.7708 11.8749 21.7708H11.0833Z"
                fill={color}/>
            <path
                d="M16.625 21.7708C15.9692 21.7708 15.4375 22.3025 15.4375 22.9583C15.4375 23.6141 15.9692 24.1458 16.625 24.1458H26.9167C27.5725 24.1458 28.1042 23.6141 28.1042 22.9583C28.1042 22.3025 27.5725 21.7708 26.9167 21.7708H16.625Z" fill={color}/>
        </svg>
    );
}

export default List;