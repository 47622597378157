const Air = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.17746 28.8225L34.0611 3.93905C32.4959 2.37404 29.531 3.36231 23.6012 5.33883L8.8268 10.2635C5.53058 11.3622 3.88246 11.9115 3.3822 13.1201C3.26463 13.4041 3.19353 13.7053 3.17167 14.0119C3.0786 15.3166 4.30703 16.545 6.76389 19.0017L7.21139 19.4492C7.61466 19.8525 7.81628 20.0542 7.96871 20.2785C8.26934 20.7212 8.43997 21.2393 8.4613 21.7738C8.47211 22.0451 8.42979 22.3271 8.34515 22.8909C8.0353 24.9552 7.88037 25.9874 8.06289 26.7816C8.24326 27.5666 8.63547 28.2679 9.17746 28.8225Z"
                  fill={color}/>
            <path opacity="0.5"
                  d="M27.7054 29.2689L32.6622 14.3991C34.6388 8.46958 35.6271 5.5048 34.062 3.93979L9.17847 28.8233C9.72953 29.3873 10.4355 29.7996 11.231 29.9944C12.0226 30.188 13.0569 30.0476 15.1254 29.7668L15.2385 29.7515C15.8216 29.6723 16.1132 29.6329 16.3931 29.6497C16.9014 29.6804 17.3922 29.8458 17.8151 30.1293C18.0482 30.2854 18.2562 30.4934 18.6723 30.9094L19.0701 31.3073C21.4614 33.6984 22.6569 34.894 23.9253 34.8318C24.2751 34.8147 24.6185 34.7334 24.939 34.5925C26.1013 34.0809 26.636 32.4769 27.7054 29.2689Z"
                  fill={color}/>
        </svg>);
}

export default Air;