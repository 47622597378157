import {createGlobalStyle} from 'styled-components';

import HelveticaNeueCyr from './../fonts/Helvetica-Neue.ttf';
import RubikRegular from './../fonts/Rubik-Regular.ttf';
import RubikMedium from './../fonts/Rubik-Medium.ttf';
import RubikBold from './../fonts/Rubik-Bold.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'HelveticaNeueCyr';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url(${HelveticaNeueCyr}) format('truetype'),
    }

    @font-face {
        font-family: 'Rubik';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url(${RubikRegular}) format('truetype'),
    }

    @font-face {
        font-family: 'Rubik';
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        src: url(${RubikMedium}) format('truetype'),
    }
    
    @font-face {
        font-family: 'Rubik';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${RubikBold}) format('truetype'),
    }
`;