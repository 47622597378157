import React, {useEffect, useState} from 'react';
import { useTheme } from '../context/ThemeContext';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Cart/Header';
import {CartStyles} from '../styles/main.css';
import Details from '../components/Cart/Details';
import { post } from '../lib/api';

const { CartWrapper } = CartStyles;

const Cart = () => {
    const { theme } = useTheme();
    const user = useSelector(state => state.userInfo);
    const cart = user.cart;

    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    const goToBack = () => {
        navigate(-1);

    }

    useEffect(() => {
        backButton.show();
        backButton.onClick(goToBack);

        return () => {
            backButton.hide();
            backButton.offClick(goToBack);
        }
    }, []);
    const mainButton = tg.MainButton;
    const cartItems = cart.items;
    const [totalAmount, setTotalAmount] = useState(0);
    const [accountLinkValue, setAccountLinkValue] = useState('');
    const [accountLoginValue, setAccountLoginValue] = useState('');
    const [accountPasswordValue, setAccountPasswordValue] = useState('');
    const [promoValue, setPromoValue] = useState('');
    const [discount, setDiscount] = useState(user.discount);
    const [isLink, setIsLink] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isPassword, setIsPassword] = useState(false);

    const completeOrder = () => {
        mainButton.disable();
        mainButton.showProgress();
        let data = {
            amount: totalAmount,
            items: cart.items,
            promo: user.promoCode
        }
        if (isLink) {
            data.accountLink = accountLinkValue;
        }
        if (isLogin) {
            data.accountLogin = accountLoginValue;
        }
        if (isPassword) {
            data.accountPassword = accountPasswordValue;
        }
        post('/payments/create', {token: user.token}, data).then((res) => {
            tg.disableClosingConfirmation();
            tg.close();
            // window.location.href = res.link;
        }).catch((err) => {
            tg.showAlert('Произошла ошибка. Попробуйте еще раз');
            mainButton.enable();
            mainButton.hideProgress();
        })
    }

    const setEnableMainButton = (enable) => {
        if (enable && cartItems.length > 0 && totalAmount >= 100) {
            mainButton.color = theme.mainButtonColor;
            mainButton.textColor = theme.mainButtonTextColor;
            mainButton.enable();
        } else {
            mainButton.color = "#D9D9D9"
            mainButton.textColor = "#FFFFFF"
            mainButton.disable();
        }
    }

    useEffect(() => {
        post('/users/updateCart', {token: user.token}, {cart: cart}).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }, [cart]);

    useEffect(() => {
        if (cartItems.length > 0) {
            setTotalAmount(cartItems.reduce((acc, item) => {
                let price = acc + parseInt(item.price);
                if (user.discount > 0) {
                    price = price - (price * (user.discount / 100));
                }
                return price
            }, 0));
        } else {
            setTotalAmount(0);
        }
    }, [cart, user]);

    useEffect(() => {
        setIsLink(cartItems.some(item => item.need_link === true));
        setIsLogin(cartItems.some(item => item.need_login === true));
        setIsPassword(cartItems.some(item => item.need_password === true));
    }, [cart]);

    useEffect(() => {
        if (totalAmount === 0) {
            mainButton.color = "#D9D9D9"
            mainButton.disable();
        }
    }, [totalAmount]);

    useEffect(() => {
        mainButton.text = "Перейти к оплате"
        mainButton.onClick(completeOrder);
        mainButton.show();
        return () => {
            mainButton.offClick(completeOrder);
        }
    }, [cart, totalAmount, accountLinkValue, accountLoginValue, accountPasswordValue, promoValue, discount, user]);

    return (
        <>
            <CartWrapper>
                <Header cart={cart} totalAmount={totalAmount} theme={theme} />
                <Details
                    setEnable={setEnableMainButton}
                    theme={theme}
                    accountLinkValue={accountLinkValue}
                    accountLoginValue={accountLoginValue}
                    accountPasswordValue={accountPasswordValue}
                    promoValue={promoValue}
                    setAccountLinkValue={setAccountLinkValue}
                    setAccountLoginValue={setAccountLoginValue}
                    setAccountPasswordValue={setAccountPasswordValue}
                    setPromoValue={setPromoValue}
                    discount={discount}
                    setDiscount={setDiscount}
                    isLink={isLink}
                    isLogin={isLogin}
                    isPassword={isPassword}
                    user={user}
                />
            </CartWrapper>
        </>
    );
}

export default Cart;
