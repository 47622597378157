import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ItemInfoStyles } from '../../styles/main.css';

const { ItemInfoFaqHeader, ItemInfoFaqContent } = ItemInfoStyles;

export default function AccordionItem({ title, content, theme }) {
    return (
        <div style={{ marginTop: '5px' }}>
            <Accordion style={{backgroundColor: theme.cardColor, color: theme.titleColor, borderRadius: '12px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon htmlColor={'#fff'}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ItemInfoFaqHeader colorTheme={theme}>{title}</ItemInfoFaqHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <ItemInfoFaqContent colorTheme={theme}>{content}</ItemInfoFaqContent>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}